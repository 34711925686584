.cardContainer {
  background-color: var(--white);
  border-radius: 8px;
  padding: 32px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
}
.textContainer{
  margin-bottom: 24px;
  text-align: center;
}
.buttonPosition {
  display: flex;
  place-content: center;
  flex-direction: column;
  padding-top: 16px;
}

.registerButtonPosition {
  display: flex;
  flex-direction: row;
}
.registerButtonContainer {
  display: flex;
  justify-content: center;
  align-content: center;
  padding-top: 28px;
}

.container {
  background-color: var(--violet-extra-light);
  padding-top: 140px;
  padding-bottom: 340px;
}

.fotgotPasswordButtonPosition {
  padding-top: 12px;
  display: flex;
  justify-content: center;
  align-content: center;
}

.checkImagePosition {
  display: flex;
  justify-content: center;
  padding-block: 32px;
}
