.container {
  background-color: var(--white);
  border-radius: 16px;
  padding: 24px !important;
  margin-block-end: 100px;
}

.infoContainer {
  padding-bottom: 48px;
}
.buttonContainer {
  padding-top: 8px;
}
.selectContainer {
  padding-top: 2px;
}
.checkboxContainer {
  padding-block: 4px;
}
.headingContainer{
  padding:0 !important;
}
.headingContainer button{
  padding:0 !important;
}
.input:focus{
  outline: 0;
  box-shadow: 0;
  border:0;
}
.input{
  padding: 12px 16px !important;
}

.error{
  color: var(--danger);
  font-size: 14px;
  margin-top: -6px;
  margin-bottom: 12px;
}

.addButton{
  color: #00A8FF;
  font-weight: 600;
  cursor: pointer;
  margin-top: 24px;
}
.deleteButton{
  color: #e3412d;
  font-weight: 600;
  cursor: pointer;
  margin:0;
}

.discountContainer input{
  margin-bottom: 24px;
  padding: 12px 16px !important;
}