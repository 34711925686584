.container{
  display: flex;
}
.checkContainer {
  background-color: var(--primary);
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-content: center;
  border-radius: 4px;
  margin-right: 24px;
}
