.buttonContainer {
  padding-top: 40px;
}

.cardsContainer {
  padding-top: 16px;
}
.arrowContainer {
  padding-bottom: 16px;
}

.discountButton{
  display: flex;
  align-items: center;
  cursor: pointer;
}
.discountButton img{
  margin-right: 8px;
}
.discountButton p{
  margin:0;
  color:#A153DF;
  font-weight: 500;
  font-size: 14px;
  font-feature-settings: 'pnum' on, 'lnum' on;
}
.input{
  border:0 !important;
  background-color: var(--extra-white) !important;
  border-radius: 8px !important;
  padding: 12px 16px !important;
  font-feature-settings: 'pnum' on, 'lnum' on;
}

.codeContainer{
  display: flex;
  margin-top: 12px;
  justify-content: space-between;
}
.applyButton{
  padding: 14px 16px;
  border-radius: 8px;
  border: 1.5px solid var(--bsicos-negro, #1B1B1B);
  background: var(--bsicos-blanco, #FFF);
  font-size: 14px;
  font-weight: 600;
  margin-left: 8px;
}

.couponContainer{
  display: flex;
  align-items: center;
}
.couponContainer p{
  font-size: 14px;
  font-weight: 600;
  margin:0;
  margin-left: 8px;
  font-feature-settings: 'pnum' on, 'lnum' on;
}
.deleteButton{
  color: var(--danger);
  font-size: 14px;
  background-color: white;
  border:0;
  font-weight: 500;
}

.error{
  color: var(--danger);
  font-size: 14px;
  margin-top: 8px;
}

.label{
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color:#1B1B1B !important;
}