.titleContainer{
    margin-bottom: 20px;
}
.itemContainer{
    margin-bottom: 12px;
}
.btnContainer{
    margin-top: 24px;
}
.btnContainer button{
    width: max-content !important;
}
.input,
.select{
  background: var(--extra-white);
  border-radius: 8px;
  border: 0px solid rgb(200, 200, 200);
  padding-block: 8px;
  /* margin-top: 40px; */
  /* margin-inline: 4px; */
  padding: 12px 16px;
  width: 100%;
  font-feature-settings: 'pnum' on, 'lnum' on;
}
.select{
  margin-bottom: 24px;
}

.inputContainerSm,
.inputContainer,
.dniInputContainer{
    margin-top: 12px;
}
.creditCardContainer{
    margin-bottom: 24px;
}
.btnContainer{
    margin-top: 24px;
}
.cvcContainer{
    display: flex;
}
.inputContainerSm:first-of-type{
    padding-right: 10px;
}
.inputContainerSm{
    width: 50%;
}

@media (max-width: 576px){  
    .cvcContainer{
        flex-direction: column;
    } 
    .inputContainerSm:first-of-type{
        padding-right: 0px;
    }
    .inputContainerSm{
        width: 100%;
    }
}