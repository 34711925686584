.cardContainer {
  background-color: var(--white);
  border-radius: 8px;
  padding: 32px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
}

.buttonContainer{
  display: flex;
  place-content: center;
  flex-direction: column;
  padding-top: 32px;
  margin-bottom: 24px;
}

.registerButtonPosition {
  display: flex;
  flex-direction: row;
}
.registerButtonContainer {
  display: flex;
  justify-content: center;
  align-content: center;
  padding-top: 28px;
}

.container {
  background-color: #fafafa;
  min-height: 100vh;
  display: flex;
  align-items: center;
}

.fotgotPasswordButtonPosition {
  padding-top: 12px;
  display: flex;
  justify-content: center;
  align-content: center;
}
.titleContainer{
  margin-bottom: 40px;
}


@media (max-width: 576px) {
  .cardContainer {
    padding: 24px 16px;
  }
}
