.creditCardContainer{
  margin-bottom: 24px;
  width:fit-content !important;
}
.titleContainer{
    margin-bottom: 20px;
}
.itemContainer{
    margin-bottom: 24px;
}
.btnContainer{
    margin-top: 12px;
}
.btnContainer button{
    width: max-content !important;
}

.subscriptionContainer{
    margin: 12px 0px;
}
.subscriptionContainer p{
    margin-bottom: 12px;
}
.alertCancelled{
  background-color: var(--danger);
  padding: 16px;
  border-radius: 8px;
  margin-top: 16px;
}
.alertCancelled p{
  color: white;
  font-size: 14px;
  line-height: 18px;
  margin:0;
}

.alert{
    background-color: var(--violet-light);
    padding: 16px;
    border-radius: 8px;
    margin-top: 16px;
}
.alert p{
    color: var(--violet);
    font-size: 14px;
    line-height: 18px;
    margin:0;
}

.delete:hover,
.delete:active{
  padding: 4px 16px !important;
  border: 1px solid #E3412D !important;
  border-radius: 4px !important;
  color:#E3412D !important;
  background-color: white !important;
}
.delete{
  padding: 4px 16px !important;
  border: 1px solid #fff !important;
  color:white !important;
  background-color: #E3412D !important;
  transition: all 0.2s ease-in;
  margin-right: 12px;
}

.cancel{
  padding: 4px 16px !important;
  border: 1px solid var(--grey-3) !important;
  border-radius: 4px !important;
  color:var(--grey-3) !important;
  background-color: white !important;
  margin-right: 12px;
}
.cancel:hover,
.cancel:active{
  border: 1px solid #fff !important;
  color:white !important;
  background-color: var(--grey-3) !important;
  transition: all 0.2s ease-in;
}
.cardsContainer{
  margin-bottom: 24px;
}


.discountButton{
  display: flex;
  align-items: center;
  cursor: pointer;
}
.discountButton img{
  margin-right: 8px;
}
.discountButton p{
  margin:0;
  color:#A153DF;
  font-weight: 500;
  font-size: 14px;
  font-feature-settings: 'pnum' on, 'lnum' on;
}
.input{
  border:0 !important;
  background-color: var(--extra-white) !important;
  border-radius: 8px !important;
  padding: 12px 16px !important;
  font-feature-settings: 'pnum' on, 'lnum' on;
}

.codeContainer{
  display: flex;
  margin-top: 12px;
  justify-content: space-between;
}
.applyButton{
  padding: 14px 16px;
  border-radius: 8px;
  border: 1.5px solid var(--bsicos-negro, #1B1B1B);
  background: var(--bsicos-blanco, #FFF);
  font-size: 14px;
  font-weight: 600;
  margin-left: 8px;
}

.couponContainer{
  display: flex;
  align-items: center;
}
.couponContainer p{
  font-size: 14px;
  font-weight: 600;
  margin:0;
  margin-left: 8px;
  font-feature-settings: 'pnum' on, 'lnum' on;
}
.deleteButton{
  color: var(--danger);
  font-size: 14px;
  background-color: white;
  border:0;
  font-weight: 500;
}
