/* General */
.paragraph {
  color: black;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  margin: 8px 0px;
  font-family: "Raleway", sans-serif !important;
}

/* Colors */
.primary {
  color: var(--primary) !important;
}
.black {
  color: black;
}
.violet {
  color: var(--violet-dark);
}
.extra-dark-grey {
  color: var(--extra-dark-grey) !important;
}
.light-grey {
  color: var(--grey-2);
}
.grey {
  color: var(--grey-3);
}
.dark-grey {
  color: var(--grey-4);
}
.white {
  color: var(--white) !important;
}
.warning {
  color: var(--warning) !important;
}
.info {
  color: var(--info) !important;
}
.error {
  color: var(--error) !important;
}
.success {
  color: var(--success) !important;
}
.banner-green {
  color: var(--verde) !important;
}
.banner-violet {
  color: var(--violet) !important;
}
.lightBlue {
  color: var(--info);
}

/* margin */
.marginSm {
  margin: 4px 0px;
}
.marginNull {
  margin: 0px 0px;
}
/* Sizes */
.v {
  font-weight: inherit;
  font-size: 45px;
  line-height: 56px;
}
.iv {
  font-weight: inherit;
  font-size: 35px;
  line-height: 48px;
}
.xxxl {
  font-weight: inherit;
  font-size: 32px;
  line-height: 48px;
}
.xxl {
  font-weight: inherit;
  font-size: 24px;
  line-height: 24px;
}
.xl {
  font-weight: inherit;
  font-size: 20px;
  line-height: 32px;
}
.lg {
  font-weight: inherit;
  font-size: 18px;
  line-height: 28px;
}
.md {
  font-weight: inherit;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
}
.sm {
  font-weight: inherit;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
}
.xs {
  font-weight: inherit;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.3px;
}
.xxs {
  font-weight: inherit;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.3px;
}

/* Alignment */
.left {
  text-align: left;
}
.center {
  text-align: center;
}
.right {
  text-align: right;
}

/* Weight */
.light {
  font-weight: 300 !important;
}
.regular {
  font-weight: 400 !important;
}
.medium {
  font-weight: 500 !important;
}
.semiBold {
  font-weight: 600 !important;
}
.bold {
  font-weight: 700 !important;
}

/* Overflow */
.ellipsis {
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  position: absolute;
}
.clip {
  text-overflow: clip;
}

.center {
  text-align: center;
}

.underlined {
  text-decoration: underline;
}

@media (max-width: 576px){
  .v {
    font-size: 40px;
    line-height: 45px;
  }
  .xl {
    font-weight: inherit;
    font-size: 18px;
    line-height: 32px;
  }
}