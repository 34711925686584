.btn {
  border: 1px solid rgb(200, 200, 200);
  border-radius: 4px;
  padding-inline: 12px;
}

.inputContainer {
  background-color: rgb(244, 244, 244);
  display: flex;
  place-content: center;
  flex-direction: column;
  padding: 24px;
  border: 1px solid rgb(200, 200, 200);
  border-radius: 8px;
  margin: 12px 0px;
}

.textStyle {
  text-align: center;
}

.buttonContainer {
  display: flex;
  justify-content: center;
}

.container ul{
  list-style-type: none;
  width: 100%;
}
.container ul li{
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--grey-1);
  padding: 8px 8px 8px 0px;
}

.img img{
  width: 32px;
  height: 32px;
  border-radius: 4px;
  object-fit: cover;
  margin-right: 10px;
  border: 1px solid var(--grey-1);
}
.close{
  cursor: pointer;
}

.error{
  color: var(--danger) !important;
  margin-right: 10px;
}

.inputError{
  border: 1px solid var(--danger);
}