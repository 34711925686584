.buttonContainer {
  padding-top: 32px;
}

.cameraBtn {
  width: 112px;
  height: 112px;
  /* Básicos/Grises/Gris-7 */

  background: var(--extra-white);
  /* Básicos/Grises/Gris-6 */

  border: 1px solid var(--grey-1);
  border-radius: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.categoriesContainer {
  padding-block: 16px;
}
.arrowContainer {
  padding-bottom: 16px;
}

.input{
  border:0 !important;
  background-color: var(--extra-white) !important;
  border-radius: 8px !important;
  padding: 12px 16px !important;
}
.error{
  border:1px solid var(--danger) !important;
}
.errorValidation{
  color: var(--danger) !important;
  font-size: 14px;
  padding-top: 4px;
}
.inputContainer {
  margin-bottom: 24px;
}