.cardContainer {
  background-color: var(--white);
  border-radius: 8px;
  padding: 32px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
}
.textContainer{
  margin-bottom: 24px;
}
.buttonPosition {
  display: flex;
  place-content: center;
  flex-direction: column;
  padding-top: 32px;
}

.registerButtonPosition {
  display: flex;
  flex-direction: row;
}
.registerButtonContainer {
  display: flex;
  justify-content: center;
  align-content: center;
  padding-top: 28px;
}

.container {
  background-color: #fafafa !important;
  min-height: 100vh;
  display: flex;
  align-items: center;
}

.fotgotPasswordButtonPosition {
  padding-top: 12px;
  display: flex;
  justify-content: center;
  align-content: center;
}


.input{
  border:0 !important;
  background-color: var(--extra-white) !important;
  border-radius: 8px !important;
  padding: 12px 16px !important;
}