.container {
  background-color: var(--primary);
  border-radius: 8px;
  padding-inline: 20px;
  padding-block: 12px;
  margin-left: 4px;
}

.container p {
  font-size: 16px;
  color: white !important;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.15);
}
