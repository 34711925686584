.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 17px 16px;
  min-height: 66px;
  width: "100%";
  border: 1px solid var(--grey-1);
  border-radius: 8px;
  margin-block: 8px;
}

.formCheckInput {
  border: 1px solid #a4acb8;
  border-radius: 50%;
  height: 23px;
  padding: 0px !important;
  width: 23px;
}

.formCheckInput:checked[type="radio"] {
  background-image: url("/src/views/public/vector.svg");
}
input[type="radio"] {
  accent-color: var(--primary);
}
.textContiner {
  margin-left: 16px;
}
