.inputContainerIntagram {
  padding: 12px 16px;
  gap: 10px;
  /* width: 437px; */

  border: 1px solid #c8c8c8;
  border-radius: 0px 8px 8px 0px;
  background-color: var(--white);
  width: 100%;
}
.inputContainer {
  padding: 12px 16px;
  gap: 10px;
  /* width: 487px; */
  width: 100%;
  border: 1px solid #c8c8c8;
  border-radius: 8px;
  background-color: var(--white);
}

.inputPosition {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap !important;
}

.arroba {
  padding: 12px 16px;
  gap: 10px;

  width: 51px;

  /* Básicos/Grises/Gris-6 */

  background: var(--grey-1);
  /* Básicos/Grises/Gris-5 */

  border: 1px solid #c8c8c8;
  border-radius: 8px 0px 0px 8px;
}

.error{
  color: var(--danger);
  margin-top: -6px;
  margin-bottom: 12px;
}

.errorInput{
  border: 1px solid var(--danger)
}