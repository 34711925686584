.container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0px 24px 0px;
}

.step{
    height: 4px;
    width: calc(100%/7 - 4px);
    border-radius: 4px;
    background-color: var(--grey-1);
}
.completed{
    background-color: var(--primary) !important;
}

.active{
    background-color: var(--primary) !important;
    animation-name: progress;
    animation-duration: 1.4s;
    animation-delay: 0.05s;
    animation-timing-function: ease-out;
    animation-fill-mode:forwards;
    animation-iteration-count:infinite;
    animation-direction: alternate;
    transform-origin: left;
    opacity:0;
}

@keyframes progress {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}