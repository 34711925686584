.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 17px 16px;
  width: "100%";
  margin-block: 8px;
  border-radius: 8px;
  border: 1px solid var(--grey-1);
}
.contentContainer{
  display: flex;
  flex-direction: column;
  margin-left: 16px;
}
.containerChecked {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 17px 16px;
  width: "100%";
  margin-block: 8px;
  background: linear-gradient(
    91.3deg,
    #4b1459 0%,
    #3d207a 51.39%,
    #122066 100%
  );

  border-radius: 8px;
  border: 1px solid var(--grey-1);
}
.bodyContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.tagContainer p{
  line-height: 0;
}
.formCheckInput {
  border: 2px solid var(--white);
  border-radius: 50%;
  /* font: inherit; */
  height: 23px;
  padding: 0 !important;
  width: 23px;
}
input[type="radio"] {
  accent-color: var(--white);
  border: 2px solid var(--white);
}
.textContiner {
  display: flex;
}
.tagContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 8px 1px 8px;
  gap: 2px;
  background: var(--white);
  border-radius: 16px;
  margin-left: 8px;
}
.tagContainerNotSelected {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 8px 1px 8px;
  gap: 2px;
  background: var(--white);
  border-radius: 16px;
  margin-left: 8px;
  border: 1px solid black;
}
.popularContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.planName {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.price{
  font-feature-settings: 'pnum' on, 'lnum' on;
}
.checkInputTitleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.priceMonthContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
.discountContainer{
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
.discountContainer p{
  margin:0;
}
del{
  margin-right: 4px;
}

/* Media queries - Mobile */
@media (max-width: 576px) {
  del{
    margin-right: 0px;
  }
  .discountContainer{
    flex-direction: column;
    text-align: right;
  }
  .bodyContainer{
    position:relative;
  }
  .tagContainer p{
    font-size: 8px;
    line-height: 0;
  }
  .tagContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 6px;
    background: var(--white);
    border-radius: 16px;
    margin-left: 0px;
    position:absolute !important;
    top:-6px;
    right:-6px;
  }
  .tagContainer img,
  .tagContainerNotSelected img{
    width: 10px;
  }
  .tagContainerNotSelected p {
    font-size: 8px !important;
    line-height: 0;
  }
  .tagContainerNotSelected img{
    width: 10px;
  }
  .tagContainerNotSelected {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 6px !important;
    background: var(--white);
    border-radius: 16px;
    margin-left: 0px;
    position:absolute !important;
    top:-6px;
    right:-6px;
  }
  .popularContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .contentContainer{
    flex-direction: column;
  }
  .planName p {
    font-size: 14px;
  }
  .month p {
    font-size: 14px;
  }
  .price p {
    font-size: 20px;
  }
  .sucursales p {
    font-size: 12px;
  }
  .tagDiscount{
    position:static !important;
    margin-left: 4px;
    top:0 !important;
    right:0 !important;
    line-height: 0 !important;
    height: fit-content !important;
    padding: 4px 6px !important;
  }
}