.iconContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 2px;
  gap: 8px;
  width: 24px;
  height: 24px;
  background: #FFF5DD;
  border-radius: 4px;
  margin-right: 14px;
}
.iconContainer img{
  width: 20px;
  height: 20px;
}
.headPosition{
  border-top: 1px solid var(--grey-1);
  padding-top:20px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
}
.headPositionFirst{
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
}

.cardInfo{
  display: flex;
}
.contentContainer{
  width: 100%;
  margin-bottom: 20px;
}
.planContainer{
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.planContainer p{
  font-feature-settings: 'pnum' on, 'lnum' on;
}

@media (max-width: 590px) {
  .contentContainer {
    padding-inline: 12px;
  }
}
@media (max-width: 375px) {
  .contentContainer {
    padding-inline: 14px;
  }
}

.headPosition {
  display: flex;
  justify-content: space-between;
}
