.container {
  padding: 12px;
}

.bodyContainer {
  padding: 24px;
  background-color: var(--white);
  border-radius: 16px;
  height: 100%;
}

.titleContainer {
  padding-bottom: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.titleContainer p{
  margin:0;
}

.infoContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.buttonContainer {
  padding-block: 4px;
  padding-inline: 24px;
  border-width: 1px;
}

.btnCard {
  padding-block: 4px;
  padding-inline: 24px;
  border-width: 1px;
}
.imgPosition {
  object-fit: cover;
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

.imgContainer {
  width: 56px;
  height: 56px;
  /* Básicos/Grises/Gris-7 */

  background: var(--extra-white);
  /* Básicos/Grises/Gris-6 */

  border: 1px solid var(--grey-1);
  border-radius: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.btn {
  padding-inline: 4px;
}
.btnPosition {
  padding-top: 12px;
}
