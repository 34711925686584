.inputContainer {
  padding-block: 12px;
  width: 100%;
}
.inputContainerSm {
  padding-block: 12px !important;
  width: 48%;
}
.inputContainerRow {
  padding-block: 8px;
}
.input {
  padding-inline-start: 8px;
}

.dniInputContainer {
  padding-block: 0px;
}

.cvcContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media (max-width: 767px) {
  .cvcContainer {
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 767px) {
  .inputContainerSm {
    padding-block: 4px;
    width: 100%;
  }
}

.input,
.select{
  background: var(--extra-white);
  border-radius: 8px;
  border: 0px solid rgb(200, 200, 200);
  padding-block: 8px;
  /* margin-top: 40px; */
  /* margin-inline: 4px; */
  padding: 12px 16px;
  width: 100%;
  font-feature-settings: 'pnum' on, 'lnum' on;
}
.select{
  margin-bottom: 24px;
}

.buttonContainer {
  padding-top: 32px;
}
.arrowContainer {
  padding-bottom: 16px;
}

.creditCardContainer {
  padding-top: 24px;
  padding-bottom: 12px;
}
