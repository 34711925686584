.input:focus{
  outline: 0;
  box-shadow: 0;
  border:0;
}
.input{
  padding: 12px 16px !important;
}
.input[type="checkbox"]{
  padding: 0 !important;
  width: 18px;
  height: 18px;
}
.error{
  border: 1px solid var(--error) !important;
}
.errorValidation{
  margin-top: 4px;
}
.errorValidation p{
  color: var(--error) !important;
  font-size:12px;
}

.time {
  padding-block: 8px;
  padding-inline: 16px;
  border-radius: 8px;
  border: 1px solid rgb(200, 200, 200);
}
.signIn {
  background: var(--extra-white);
  border-radius: 8px;
  border: 0px solid rgb(200, 200, 200);
  padding-block: 8px;
  width: 100%;
  padding-inline-start: 8px;
}

.onboarding{
  border:0 !important;
  background-color: var(--extra-white) !important;
  border-radius: 8px !important;
  padding: 12px 16px !important;
}

