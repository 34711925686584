.container {
  display: flex;
  flex-direction: row;
}

.bodyContainer {
  padding-inline: 6px;
  display: flex;
  justify-content: center;
  align-content: center;
}

.arrowPosition {
  margin-left: 4px;
}
