.formCheckInput {
  border: 2px solid var(--white);
  border-radius: 50%;
  /* font: inherit; */
  height: 14px;
  padding: 0 !important;
  width: 14px;
  margin-right: 8px;
}
input[type="radio"] {
  accent-color: var(--violet-dark);
  border: 2px solid var(--white);
}

.buttonContainer {
  padding-top: 32px;
}

.inputContainer {
  padding-top: 2px;
}
.signIn {
  background: var(--extra-white);
  border-radius: 8px;
  border: 0px solid rgb(200, 200, 200);
  padding-block: 8px;
  width: 100%;
  padding-inline-start: 8px;
}
.billCheckInputContainer {
  padding-top: 4px;
  padding-bottom: 16px;
}

.titleContainer p{
  margin-bottom: 8px;
}
.arrowContainer {
  padding-bottom: 16px;
}

.input{
  border:0 !important;
  background-color: var(--extra-white) !important;
  border-radius: 8px !important;
  padding: 12px 16px !important;
  margin-bottom: 24px;
}