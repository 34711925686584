.iconContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 2px;
  gap: 8px;

  width: 24px;
  height: 24px;

  background: var(--violet-light);
  border-radius: 4px;
}
.arrowContainer {
  padding-bottom: 16px;
}
.buttonContainer{
  margin-top: 20px;
}
