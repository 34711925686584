/* General */
.button {
  border-radius: 8px !important;
  height: auto !important;
  font-size: 14px !important;
  line-height: 24px !important;
  font-weight: 600 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border: 0px !important;
  outline: none !important;
  cursor: pointer !important;
  width: 100% !important;
  padding: 14px 16px !important;
}

/* Icon Buttons */
.iconOnly {
  width: auto !important;
  margin-left: 4px;
}
.iconOnly .icon {
  height: 20px;
}
.iconFirst .icon {
  height: 20px;
  order: 0;
  margin-right: 8px;
}
.iconLast .icon {
  height: 20px;
  order: 2;
  margin-left: 8px;
}

/* Sizes */
.sm {
  padding: 4px 16px;
}
.md {
  padding: 8px 24px;
}
.lg {
  padding: 12px 48px;
}

.sm.iconOnly {
  padding: 6px;
}
.md.iconOnly {
  padding: 10px;
}
.lg.iconOnly {
  padding: 12px;
}

/* Color*/

.dark {
  background-color: black !important;
  color: white !important;
  transition: all 0.4s ease;
}
.dark:hover,
.dark:active{
  transition: all 0.4s ease;
  filter: opacity(0.85);
}
.violet-outline {
  background-color: transparent;
  color: var(--violet-dark);
  border: 0px;
}
.violet-outline:disabled{
  background-color: transparent;
  color: var(--grey-3);
  border: 0px;
}

.left {
  align-self: flex-end;
}
.buttonInline {
  padding-inline: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-width: 0px;
  background-color: transparent;
  flex-direction: row;
}

.reverse {
  flex-direction: row-reverse;
}
.mediumSpacing {
  padding-inline: 32px;
}
.extraSmallSpacing {
  padding-inline: 8px;
}

.notPaddingInline {
  padding-inline: 0px;
}

.outlineInfoBtn {
  border: 1px solid var(--info);
}

.outlineBtnSm {
  padding-inline: 16px;
  padding-block: 4px;
}
.outlineBtnMd {
  padding-inline: 24px;
  padding-block: 4px;
}
.outlineInfoBtn p {
  color: var(--info);
}
.outlineInfoBtn:hover p {
  color: var(--white);
}

.outlineInfoBtn:hover {
  border: 1px solid var(--info);
  background-color: var(--info);
}

.outlineDangerBtn {
  border: 1px solid var(--danger);
}
.outlineDangerBtn p {
  color: var(--danger);
}
.outlineDangerBtn:hover p {
  color: var(--white);
}

.outlineDangerBtn:hover {
  border: 1px solid var(--danger);
  background-color: var(--danger);
}
.iconBtn {
  background-color: transparent;
  border-width: 0px;
}

.iconPosition {
  margin-left: 4px;
}
.lightblue{
  padding: 4px 0px !important;
  color:#00A8FF !important;
  background-color: white !important;
  transition: all 0.2s ease-in;
  width: fit-content !important;
}
.edit{
  padding: 4px 16px !important;
  border: 1px solid #00A8FF !important;
  border-radius: 4px !important;
  color:#00A8FF !important;
  background-color: white !important;
  transition: all 0.2s ease-in;
  width: fit-content !important;
}
.edit:hover,
.edit:active{
  border: 1px solid #fff !important;
  color:white !important;
  background-color: #00A8FF !important;
  transition: all 0.2s ease-in;
}
.delete{
  padding: 4px 16px !important;
  border: 1px solid #E3412D !important;
  border-radius: 4px !important;
  color:#E3412D !important;
  background-color: white !important;
}
.delete:hover,
.delete:active{
  border: 1px solid #fff !important;
  color:white !important;
  background-color: #E3412D !important;
  transition: all 0.2s ease-in;
}

@media (max-width: 576px) {
  .delete, .edit{
    font-size: 12.8px !important;
    line-height: 24px !important;
    letter-spacing: 0.2px !important;
  }
}

.btnContainer{
  cursor:pointer;
  position: relative;
}
.tooltip{
  background-color: white;
  border-radius: 8px;
  padding: 10px;
  position: absolute;
  z-index: 222;
  width: 350px;
  animation: tooltip 0.2s ease-in 0s 1 alternate forwards;
  right:0;
  -webkit-box-shadow: 0px 11px 12px -13px rgba(0,0,0,0.28);
  -moz-box-shadow: 0px 11px 12px -13px rgba(0,0,0,0.28);
  box-shadow: 0px 11px 12px -13px rgba(0,0,0,0.28);
}
.tooltip p{
  margin:0;
  font-size: 12px;
}

@keyframes tooltip {
  0%{
    top:0;
    opacity:0;
  }
  100%{
    top:-40px;
    opacity: 1;
  }
}

@media (max-width:576px) {
  .tooltip{
    width: 200%;
    left:unset;
    right: 0;
    top: -60px
  }
}