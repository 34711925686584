.error{
    background-color: var(--danger) ;
    border-radius: 12px;
    color:white;
    padding: 12px 16px !important;
    width:25%;
    position: fixed;
    z-index:9999999;
    bottom: 20px;
    right: 20px;
}

.success{
    background-color: var(--success) ;
    border-radius: 12px;
    color:white;
    padding: 12px 16px !important;
    width:25%; 
    position: fixed;
    z-index:99999999;
    bottom: 20px;
    right: 20px;
}

.error p,
.success p{
    font-size: 14px;
    line-height: 16px;
}

@media (max-width: 992px){
    .success,
    .error{
        width:96%;
        right: 2%;
        bottom:unset;
        top: 16px;
    }
}