.container {
  padding-bottom: 28;
}

.tableContainer {
  background-color: var(--white);
  border-radius: 16px;
  padding: 26px;
}

.alert{
  background-color: var(--danger);
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 24px;
  display: flex;
  width:fit-content;
}
.alert p{
  color: white;
  margin:0;
  margin-right: 8px;
}

@media (max-width: 576px) {
  .tableContainer {
    background-color: transparent;
    border-radius: unset;
    padding: 0px;
  }
}