.planContainer {
  border-left: 1px solid #c8c8c8;
  padding-left: 24px !important;
}
.planContainer:first-of-type{
  border-left: 0;
  padding-left: 12px !important;
}
.priceContainer{
  margin-top: 14px;
}
.priceContainer p{
  font-feature-settings: 'pnum' on, 'lnum' on;
}
.light{
  font-weight: 500 !important;
  font-size: 14px;
  line-height: 24px;
}

@media (max-width: 576px) {
  .planContainer{
    border-left: 0;
    padding: 12px 0px;
  }
  .planContainer:first-of-type{
    border-right: 1px solid #c8c8c8;
    border-bottom: 1px solid #c8c8c8;
  }
  .planContainer:nth-of-type(2){
    border-bottom: 1px solid #c8c8c8;
  }
  .planContainer p{
    text-align:center;
  }
}
