.cardContainer {
  background-color: var(--white);
  border-radius: 8px;
  padding: 32px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
}

.container {
  background-color: #fafafa;
  min-height: 100vh;
  display: flex;
  align-items: center;
}

@media (max-width: 576px) {
  .cardContainer {
    padding: 24px 16px;
  }
}