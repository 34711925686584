.ilustrationContainer {
  margin-top: 50px;
  display: flex;
  justify-content: flex-end;
}
.logoContainer{
  margin-top: 10px;
}
.logoContainer > div{
  justify-content: flex-start;
}
.titleContainer{
  margin:26px 0px 40px 0px;
}
.backgroundColorContainer {
  background-color: #fafafa;
  box-shadow: 3px 0px 16px 1px rgba(0,0,0,0.10) inset;
  -webkit-box-shadow: 3px 0px 16px 1px rgba(0,0,0,0.10) inset;
  -moz-box-shadow: 3px 0px 16px 1px rgba(0,0,0,0.10) inset;
}

.shadowContainer {
  background: var(--white);
  border-radius: 30px !important;
  min-height: 100vh;
}

.titlePosition {
  padding-top: 20px;
}
.bodyPosition {
  padding-block: 30px;
}
.subTitlePosition {
  padding-top: 32px;
  padding-bottom: 28px;
}
.suscriptionContainer{
  margin-bottom: 40px;
}

.imgPosition {
  width: 100%;
  max-width: 523px;
  height: auto;
  right: -12px;
  top: 0;
  position: relative;
  padding-bottom: 24px;
}
.registerBtnContainer {
  padding-bottom: 20px;
  width: fit-content;
}
.registerBtnContainer button{
  padding-inline: 40px !important;
}


@media (max-width: 576px) {
  .shadowContainer {
    background: var(--white);
    border-radius: 8px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .registerBtnContainer {
    padding-block: 10px;
    width: 100%;
  }
  .backgroundColorContainer {
    box-shadow: unset;
  }
}

@media (min-width:577px) and (max-width:1200px){
  .titlePosition {
    padding-top: 50px;
  }
  .shadowContainer {
    background: var(--white);
    border-radius: 8px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .imgPosition{
    max-width: 85%;
  }
  .backgroundColorContainer {
    box-shadow: unset;
  }
}
