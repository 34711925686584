.buttonContainer {
  padding-left: 26px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.cameraBtn {
  width: 112px;
  height: 112px;
  /* Básicos/Grises/Gris-7 */

  background: var(--extra-white);
  /* Básicos/Grises/Gris-6 */

  border: 1px solid var(--grey-1);
  border-radius: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: center;
}
.imgPosition {
  object-fit: cover;
  height: 99%;
  width: 99%;
  border-radius: 50%;
  border: 1px solid var(--grey-1);
}

.error{
  color: var(--danger);
  font-size: 14px;
  margin-top: 10px;
}

.alert{
  background-color: var(--violet-light);
  padding: 16px;
  border-radius: 8px;
  margin-top: 16px;
}
.alert p{
  color: var(--violet);
  font-size: 14px;
  line-height: 18px;
  margin:0;
}