.logoPosition {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.logoContainer > div{
  justify-content: flex-start;
}
.childrenContainer {
  background: var(--white);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding: 32px 12px;
  margin-bottom: 32px;
}
.containerBackGroundColor {
  background: #fafafa;
  min-height: 100vh;
}

.imgPosition {
  object-fit: cover;
  width: 100%;
  height: 100vh;
  position:sticky;
  top:0;
  right:0;
  /* display: none; */
}

@media (min-width: 576px) and (max-width: 1200px){
  .logoContainer{
    margin-top: 60px;
  }
  .childrenContainer {
    padding: 24px 0px;
  }
}
@media (max-width: 576px){
  .childrenContainer {
    padding: 24px 0px;
  }
}