.container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.imgPosition {
  margin-right: 32px;
}
