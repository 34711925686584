.buttonContainer {
  padding-top: 40px;
}

.subTitleContainer {
  padding-bottom: 24px;
}
.arrowContainer {
  padding-bottom: 16px;
}
