.buttonContainer {
  padding-top: 24px;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-inline: 60px;
}
