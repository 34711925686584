.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.bodyContainer {
  padding-right: 16px;
  padding-block: 30px;
}
.bodyContainer img {
  width: 190px;
}


/* Media query - mobile */
@media (max-width: 576px) {
  .bodyContainer img {
    width: 130px;
  }
}
