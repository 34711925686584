/* Typography */
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700&display=swap");

* {
  font-family: "Raleway", sans-serif;
  margin: 0;
  padding: 0;
}

.form-label{
  font-size: 14px;
}

/* Colors */
:root {
  /* Saturated */
  --primary: #f7bc2f;

  /* Greyscale */
  --black: #000000;
  --grey-4: #4f4f4f;
  --grey-3: #727272;
  --grey-2: #9d9d9d;
  --grey-1: #eaeaea;
  --white: #ffffff;
  --extra-white: #f4f4f4;

  /* States */
  --success: #08b156;
  --info: #00a8ff;
  --warning: #fcc132;
  --error: #ff3819;
  --danger: #e3412d;

  /* banner colors */
  --verde: #5dbc77;
  --violet: #cc79e9;
  --violet-light: #f5e8ff;
  --violet-extra-light: #f7f5fb;
  --violet-dark: #a153df;

}

/* Override - Card Library */
.rccs__chip {
  background-image: url("./views/public/chip.svg") !important;
}
.rccs__issuer {
  background-size: 100% !important;
  width: 54px !important;
}
.rccs__card--unknown > div,
.rccs__card--front,
.rccs__card--back {
  background: linear-gradient(
    68.44deg,
    #280537 0%,
    #56034c 18.54%,
    #890058 41.98%,
    #bc005b 66.98%,
    #eb1254 100%
  );
}
.rccs__name,
.rccs__expiry__valid {
  font-family: "Raleway", sans-serif !important;
}
.rccs__expiry__valid {
  display: none;
}

/* Override - Mercadopago */
#expirationDate{
  background: var(--extra-white);
  border-radius: 8px;
  border: 0px solid rgb(200, 200, 200);
  padding-block: 8px;
  padding: 12px 16px;
  width: 100%;
  font-feature-settings: 'pnum' on, 'lnum' on;
}
input{
  font-feature-settings: 'pnum' on, 'lnum' on;
}


/* Override - CoreUI Tables */

@media screen and (max-width: 576px) {
  table {
    border: 0;
  }

  table caption {
    font-size: 1.3em;
  }
  
  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  
  table tr {
    display: block;
    background-color: #fff;
    border-radius: 8px;
    margin-bottom: 12px;
    padding:12px;
  }
  
  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: .8em;
    text-align: right;
  }
  table td button{
    font-size: .8em !important;
    text-align: right;
  }
  
  table td::before {
    content: attr(data-label);
    float: left;
    font-weight: 600;
  }
  table td:last-of-type::before,
  table td:nth-of-type(3)::before{
    margin-top: calc((30.719px - 19.2px) / 2) !important;
  }
  
  table td:last-child {
    border-bottom: 0;
  }
  .badge p{
    font-size: 12.8px !important;
  }
}