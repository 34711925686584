.time {
  padding-block: 8px;
  padding-inline: 16px;
  border: 1px solid #c8c8c8;
  border-radius: 8px;
  background-color: var(--white);
  width: 100%;
}

.inputContainer {
  padding-block: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.container {
  padding-block: 12px;
  /* width: 60%; */
}
.separator {
  padding-inline: 16px;
}
