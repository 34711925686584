.container {
  background-color: var(--white);
  border-radius: 16px;
  padding: 24px !important;
}

.infoContainer {
  padding-bottom: 48px;
}
.buttonContainer {
  padding-top: 8px;
}
.selectContainer {
  padding-top: 2px;
}
.checkboxContainer {
  padding-block: 4px;
}
.headingContainer{
  padding:0 !important;
}
.headingContainer button{
  padding:0 !important;
}
.input:focus{
  outline: 0;
  box-shadow: 0;
  border:0;
}
.input{
  padding: 12px 16px !important;
}

.error{
  color: var(--danger);
  font-size: 14px;
  margin-top: -6px;
  margin-bottom: 12px;
}

@media (max-width: 576px){
  .container{
    padding: 16px !important;
  }
}