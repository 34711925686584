.itemContainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
}
.itemContainer p{
    margin:0;
}
.companyLogo{
    width:56px;
    height: 56px;
    border-radius: 50%;
    border: 1px solid #c8c8c8;
    display: flex;
    justify-content: center;
    align-items: center;
    object-fit: cover;
}
.companyLogo img{
    width: 85%;
    border-radius: 50%;
}