.actionCell {
  text-align: right;
}
.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.btn {
  padding-inline: 4px;
}

.delete:hover,
.delete:active{
  padding: 4px 16px !important;
  border: 1px solid #E3412D !important;
  border-radius: 4px !important;
  color:#E3412D !important;
  background-color: white !important;
}
.delete{
  padding: 4px 16px !important;
  border: 1px solid #fff !important;
  color:white !important;
  background-color: #E3412D !important;
  transition: all 0.2s ease-in;
  margin-right: 12px;
}

.cancel{
  padding: 4px 16px !important;
  border: 1px solid var(--grey-3) !important;
  border-radius: 4px !important;
  color:var(--grey-3) !important;
  background-color: white !important;
  margin-right: 12px;
}
.cancel:hover,
.cancel:active{
  border: 1px solid #fff !important;
  color:white !important;
  background-color: var(--grey-3) !important;
  transition: all 0.2s ease-in;
}

@media (max-width: 576px) {
  .buttonContainer {
    justify-content: flex-end;
  }
  .btn:last-of-type{
    padding-right: 0px;
  }
}