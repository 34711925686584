.titleContainer{
    margin-bottom: 20px;
}
.itemContainer{
    margin-bottom: 24px;
}
.btnContainer{
    margin-top: 24px;
}
.btnContainer button{
    width: max-content !important;
}