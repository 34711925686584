.cardContainer {
  background-color: var(--white);
  border-radius: 8px;
  padding: 32px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
}

.textContainer{
  margin-bottom: 24px;
}

.buttonContainer {
  display: flex;
  place-content: center;
  flex-direction: column;
  margin: 40px 0px 24px 0px;
}
.resendButtonContainer {
  display: flex;
  place-content: center;
  flex-direction: column;
  align-items: center;
}

.registerButtonPosition {
  display: flex;
  flex-direction: row;
}
.registerButtonContainer {
  display: flex;
  justify-content: center;
  align-content: center;
  padding-top: 28px;
}

.container {
  background-color: #fafafa;
  padding-top: 140px;
  padding-bottom: 340px;
}

.fotgotPasswordButtonPosition {
  padding-top: 12px;
  display: flex;
  justify-content: center;
  align-content: center;
}

.input{
  border:0 !important;
  background-color: var(--extra-white) !important;
  border-radius: 8px !important;
  padding: 12px 16px !important;
}