.backdrop{
    width: 100vw;
    height:100vh;
    position:fixed;
    top:0;
    left:0;
    z-index:9999999999;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.backdrop img{
    width: 18%;
}

@media (max-width: 576px) {
    .backdrop img{
        width: 40%;
    }
}