.buttonContainer {
  padding-top: 12px;
}
.linkContainer {
  padding-top: calc(40px - 24px);
  padding-bottom: 4px;
}

.link span {
  color: var(--primary);
  cursor:pointer;
}
.link {
  color: var(--grey-3);
  text-decoration: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  cursor:pointer;
}
.link:hover{
  color:inherit;
}

.arrowButtonContainer {
  padding-bottom: 24px;
}


.subtitleContainer {
  padding-bottom: 24px;
}

.inputContainer {
  margin-bottom: 24px;
}
.arrowContainer {
  padding-bottom: 16px;
}

.input{
  border:0 !important;
  background-color: var(--extra-white) !important;
  border-radius: 8px !important;
  padding: 12px 16px !important;
}

button:disabled{
  background-color: var(--grey-2);
}

.error{
  border:1px solid var(--danger) !important;
}
.errorValidation{
  color: var(--danger) !important;
  font-size: 14px;
  padding-top: 4px;
}